import React, { useState, useEffect }  from 'react';

const Blank = ( { } ) => {
  const [is, setIs] = useState("was");

  useEffect( () => {

    setIs( () => "new text goes here" )
  }, [] );

  return (
    <div >
      {is}
    </div>
  )
}

export default Blank;
