import React, { useState } from 'react';
const PostRequest = (props) => {
  var dataToPost = props.data;
  if(dataToPost == undefined) { dataToPost = {} }
  dataToPost.authenticity_token = $('meta[name=csrf-token]').attr('content');

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataToPost)
  };

  fetch(props.url, requestOptions)
    .then(async response => {
      const data = await response.json();

      if (!response.ok) {
        const error = (data && data.message) || response.status;

        if(props[response.status] != undefined) {
          props[response.status](data);
        }
        return Promise.reject(error);
      }

      if(response.redirected && props[302] != undefined) {
        return props[302](data);
      }

      props.callback(data);
    })
    .catch((error) => {
      // alert('Sorry there was an error!' );
      console.error('[PostRequest] There was an error!', error);
    });
}

export default PostRequest
