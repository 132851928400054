import React, { useState, useEffect, Suspense, useRef }  from 'react';
import { useTranslation } from 'react-i18next';
import { PostRequest } from 'components/common/index.js';

const Form = ( { locale, location, product, product_category } ) => {
  const { t } = useTranslation('', {lng: locale});

  const [unit, setUnit] = useState('mm');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [shipping, setShipping] = useState('collection');
  const [placeholderInUnit, setPlaceholderInUnit] = useState("100.00");
  const[shippingVisible, setShippingVisible] = useState(shipping == 'shipping')

  const classA = "col-lg-2";
  const classB = "col-lg-10";
  const formRef = useRef();

  const getCsrfToken = () => {
    const metaTag = document.querySelector('meta[name="csrf-token"]');
    return metaTag ? metaTag.content : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(formSubmitted) { return }
    const data = objectifyForm($(formRef.current));
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if( !emailRegex.test(data['email']) || !formRef.current.checkValidity() ) {
      formRef.current.reportValidity()
      return;
    }

    PostRequest({
      url: `/products/${product.id}/request-quote`,
      data: data,
      callback: (_data) => {
        setThankYou( () => true)
        setFormSubmitted( () => true);
      }
    })
  }

  const lenghtInput = (label, name) => {
    return (
      <div className="form-group shop-swatch mb-7 d-flex align-items-center">
        <div className={classA}>
          <span className="fw-semibold text-body-emphasis me-7">{label} </span>
        </div>

        <div className={classB}>
          <div className="input-group">
            <input type="number" className="form-control" name={name} placeholder={placeholderInUnit} required="required" />
            <span className="input-group-text">{unit}</span>
          </div>
        </div>
      </div>)
  }

  const numberInput = (label, name, placeholder) => {
    return (
      <div className="form-group shop-swatch mb-7 d-flex align-items-center">
        <div className={classA}>
          <span className="fw-semibold text-body-emphasis me-7">{label} </span>
        </div>
        <div className={classB}>
          <input type="number" className="form-control" name={name} placeholder={placeholder} required="required" />
        </div>
      </div>)
  }

  const emailInput = () => {
    return (
      <div className="form-group shop-swatch mb-7 d-flex align-items-center">
        <div className={classA}>
          <span className="fw-semibold text-body-emphasis me-7"> {t(('quote.email'))} </span>
        </div>
        <div className={classB}>
          <input type="email" className="form-control" name="email" placeholder="info@example.com" required="required" />
        </div>
      </div>)
  }

  const shippingInput = (label, name, value) => {
    return (
      <div className="form-group shop-swatch mb-7 d-flex align-items-center">
        <div className={classA}>
          <span className="fw-semibold text-body-emphasis me-7"> {label} </span>
        </div>
        <div className={classB}>
          <input type="text" name={name} className="form-control" value={value} required={shipping == 'shipping'}  />
        </div>
      </div>)
  }

  const selectShipping = (e) => {
    // e.preventDefault();
    setShippingVisible( () => true )
    setShipping( () => 'shipping' )
  }

  const selectCollection = (e) => {
    // e.preventDefault();
    setShipping( () => 'collection' )
    setShippingVisible( () => false )
  }

  const shippingForm = () => {
    return (
      <div className="shipping_form">
        { shippingInput( t('quote.country'), 'country', location.country) }
        { shippingInput( t('quote.city'), 'city', location.city) }
      </div>
    )
  }

  const shippingSelector = () => {
    return (
      <div className="row py-8">
        <div className="col-md-5">
            <span className="fw-semibold text-body-emphasis me-7">  {t('quote.shipping')} </span>
        </div>

        <div className="col-md-7">
          <label className="mb-2 form-check">
            <input className="form-check-input" name="shipping" type="radio" value="collection" onChange={selectCollection} checked={shipping == 'collection'} />
            <span className="form-check-label">  {t('quote.collection_option')}  </span>
          </label>

          <label className="mb-2 form-check">
            <input className="form-check-input" name="shipping" type="radio" value="shipping" onChange={selectShipping} checked={shipping == 'shipping'} />
            <span className="form-check-label"> {t('quote.shipping_option')} </span>
          </label>
        </div>
      </div>);
  }

  // useEffect
  useEffect( () => {
    setPlaceholderInUnit( () => unit == 'mm' ? "100.00" : "4.00" )
  }, [unit] );

  // useEffect

  return (
    <Suspense loading='...'>
      <h1  className="mb-4 pb-2 fs-4">{t('nav.get_a_quote')}</h1>
      <hr />
      <form className="product-info-custom" ref={formRef} method="POST">
        <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
        <input type="hidden" name="unit" value={unit} />
        <input type="hidden" name="product_link" value={`https://www.boxesmade.com/products/${product_category.slug_tr}/${product.id}`} />
        <input type="hidden" name="product_info" value={`${product_category.name_tr} | ${product.name_tr}`} />

        <div className="form-group shop-swatch mb-7 d-flex align-items-center">
          <div className={classA}> <span className="fw-semibold text-body-emphasis me-7"> {t('quote.unit')} </span> </div>

          <div className={classB}> <ul className="list-inline d-flex justify-content-start mb-0">
            <li className="list-inline-item me-4 fw-semibold" onClick={ (e) => setUnit( () => 'mm') } >
              <input type="radio" id="radio1" name="radio" className="product-info-size d-none" checked={unit == 'mm'} onChange={ () => {} } />
              <label htmlFor="radio1" className="fs-14px p-4 d-block rounded text-decoration-none border" data-var="full size"> {t('quote.millimeters')} </label>
            </li>
            <li className="list-inline-item me-4 fw-semibold" onClick={ (e) => setUnit( () => 'in') } >
              <input type="radio" id="radio2" name="radio" className="product-info-size d-none" checked={unit == 'in'} onChange={ () => {} } />
              <label htmlFor="radio2" className="fs-14px p-4 d-block rounded text-decoration-none border" data-var="full size"> {t('quote.inches')} </label>
            </li>
          </ul>
          </div>

        </div>

        { numberInput( t('quote.quantity'), "quantity", 1000) }
        { lenghtInput( t('quote.width'), 'width') }
        { lenghtInput( t('quote.length'), 'length') }
        { lenghtInput( t('quote.height'), 'height') }

        { emailInput() }

        { shippingSelector() }

        { shippingVisible && shippingForm() }

        <button onClick={handleSubmit} className="btn btn-lg btn-primary btn-dark mb-7 mt-7 w-100 btn-hover-bg-primary btn-hover-border-primary">
          {thankYou ?  t('nav.thank_you_for_getting_a_quote') : t('nav.get_a_quote')}
        </button>
      </form>
    </Suspense>
  )
}

export default Form;
